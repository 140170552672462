export default function Header() {


  return (
    <div className="place-items-center text-white">
      <div className='text-4xl font-bold col-span-2 title align-center py-2'>
        Whurdal
      </div>
    </div>
  )
}